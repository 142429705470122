<template>
  <div
    class="title">
    {{ title }}
  </div>
</template>

<script>
export default {
  name: "DefaultTitle",

  props: {
    title: {
      type: String,
      required: true,
    },
  },
}
</script>

<style scoped>
title {
  font-size: 1.25rem;
  color: #3E4959;
  letter-spacing: 0px;
  font: normal normal bold 20px/27px;
}
</style>