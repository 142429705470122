<template>
  <v-container fluid fill-height
    class="d-flex align-start">
    <v-form
      ref="CalendarMovementForm"
      class="fill-height"
      style="width: 100%">
      <v-row>
        <v-col cols="2"
          class="d-flex justify-start">
          <v-btn
            fab
            class="elevation-1"
            @click="closeForm">
            <v-icon
              color="primary">
              fas fa-arrow-left
            </v-icon>
          </v-btn>
        </v-col>

        <v-col cols="4">
          <v-autocomplete
            :items="carriers"
            :loading="loading"
            v-model="form.carrierId"
            item-text="xipp_commercial_name"
            item-value="id"
            placeholder="Selecione a operadora"
            label="Operadora*"
            outlined
            hide-details
            :rules="[rule.required]"
            @input="inputForm('carrierId', $event)"
          />
        </v-col>

        <v-col cols="6"
          class="d-flex flex-row flex-row justify-end">
          <v-btn
            fab
            class="elevation-1 mr-2"
            @click="saveForm"
            :disabled="
              form.id
              ? !hasPermission('sdi_menu_calendario_edicao')
              : !hasPermission('sdi_menu_calendario_criacao')">
            <v-icon
              color="primary">
              fas fa-save
            </v-icon>
          </v-btn>

          <v-btn
            fab
            class="elevation-1"
            @click="requestDeleteCalendarMovement"
            v-if="form.id"
            :disabled="!hasPermission('sdi_menu_calendario_excluir')">
            <v-icon
              color="primary">
              fas fa-trash
            </v-icon>
          </v-btn>
        </v-col>

        <v-col cols="12">
          <v-divider/>
        </v-col>

        <v-col cols="12">
          <v-container fluid>
            <v-row>
              <v-col cols="6" class="pt-0">
                <DefaultTitle title="Setup" />
              </v-col>

              <v-col cols="6" class="pt-0 d-flex justify-end">
                <v-btn
                  fab
                  small
                  class="elevation-1"
                  @click="addNewConfig">
                  <v-icon
                    color="primary">
                    fas fa-plus
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>

          <DefaultTable
            id="CalendarConfigs"
            :items="form.configs"
            :tableConfig="tableConfig">
            <template v-slot:no-data>
              <span>
                Nenhuma configuração encontrada.
              </span>
            </template>

            <template v-slot:[`item.level`]="{ item }">
              <div
                class="d-flex justify-start">
                <v-chip-group
                  column>
                  <v-chip
                    outlined
                    style="width: 100px;"
                    class="d-flex justify-center">
                    Operadora
                  </v-chip>

                  <v-chip
                    outlined
                    disabled
                    style="width: 100px;"
                    class="d-flex justify-center">
                    Contrato
                  </v-chip>
                </v-chip-group>
              </div>
            </template>

            <template v-slot:[`item.contractIds`]="{ item }">
              <div
                class="d-flex flex-column align-end justify-start">
                <v-tooltip
                  bottom>
                  <template
                    v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      color="primary"
                      class="mb-2"
                      size="20">
                      fas fa-info
                    </v-icon>
                  </template>

                  <span>
                    Disponível em breve
                  </span>
                </v-tooltip>

                <v-autocomplete
                  :items="movementContracts"
                  :loading="loading"
                  outlined
                  item-text="name"
                  item-value="id"
                  dense
                  hide-details
                  disabled
                />
              </div>
            </template>

            <template v-slot:[`item.movementTypes`]="{ item }">
              <div
                class="d-flex justify-start">
                <v-chip-group
                  multiple
                  column
                  v-model="item.movementTypes">
                  <v-chip
                    v-for="(movementType, i) in movTypes"
                    :key="movementType.code"
                    :value="movementType.code"
                    outlined
                    :class="{
                      'red': item.movementTypes.length === 0
                    }"
                    :active-class="chipDuplicates(i, movementType.code) ? 'red' : 'primary'">
                    {{ movementType.name }}
                  </v-chip>
                </v-chip-group>
              </div>
            </template>

            <template v-slot:[`item.movementWindow`]="{ item }">
              <div
                class="d-flex flex-column justify-start">
                <v-chip
                  outlined
                  style="width: 100px;"
                  class="d-flex justify-center mb-2"
                  @click="handlerWindowField(item, 'flFutureWindow')"
                  :active-class="!item.flFutureWindow && !item.flRetroactiveWindow ? 'red' : 'primary'"
                  :class="{
                    'red': !item.flFutureWindow && !item.flRetroactiveWindow
                  }"
                  v-model="item.flFutureWindow">
                  Futura
                </v-chip>

                <v-chip
                  outlined
                  style="width: 100px;"
                  class="d-flex justify-center"
                  :active-class="!item.flFutureWindow && !item.flRetroactiveWindow ? 'red' : 'primary'"
                  :class="{
                    'red': !item.flFutureWindow && !item.flRetroactiveWindow
                  }"
                  @click="handlerWindowField(item, 'flRetroactiveWindow')"
                  v-model="item.flRetroactiveWindow">
                  Retroativa
                </v-chip>
              </div>
            </template>

            <template v-slot:[`item.daysOfWindow`]="{ item }">
              <div
                class="d-flex flex-row justify-start">
                <v-select
                  v-model="item.daysOfWindow"
                  :items="[
                    {id: 120, name: '120 dias'},
                    {id: 90, name: '90 dias'},
                    {id: 60, name: '60 dias'},
                    {id: 30, name: '30 dias'},
                  ]"
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                  hide-details
                />
              </div>
            </template>

            <template v-slot:[`item.flContractCutOffDate`]="{ item }">
              <div
                class="d-flex flex-row justify-start">
                <v-checkbox
                  dense
                  hide-details
                  v-model="item.flContractCutOffDate"
                />
              </div>
            </template>

            <template v-slot:[`item.flContractStartDate`]="{ item }">
              <div
                class="d-flex flex-row justify-start">
                <v-checkbox
                  dense
                  hide-details
                  v-model="item.flContractStartDate"
                  @click="item.flContractStartDate ? cleanSpecificDaysField(item) : false"
                />
              </div>
            </template>

            <template v-slot:[`item.specificDays`]="{ item }">
              <div
                class="d-flex flex-row justify-start">
                <v-autocomplete
                  v-model="item.specificDays"
                  @change="sortSpecificDaysField(item)"
                  :items="days"
                  multiple
                  outlined
                  dense
                  hide-details
                  :disabled="item.flContractStartDate"
                />
              </div>
            </template>

            <template v-slot:[`item.action`]="{ item, index }">
              <v-menu transition="slide-y-transition" offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="textPrimary"
                    dark
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <TodoListActions :actions="actions(item, index)" />
              </v-menu>
            </template>
          </DefaultTable>
        </v-col>
      </v-row>
    </v-form>

    <ConfirmationModal
      ref="CalendarFormModal"
      title="Atenção"
      @confirm="confirmAction"
      color="primary"
      :description="descriptionMessage"
    />
  </v-container>
</template>

<script>
import AuthorityManagementMixin from '@/shared/mixins/authorityManagement/authorityManagementMixin';
import CalendarMovementMixin from '@/shared/mixins/calendarMovement/calendarMovementMixin';
import ConfirmationModal from '../Modals/ConfirmationModal/ConfirmationModal.vue';
import DefaultButton from '../Default/DefaultButton/DefaultButton.vue';
import DefaultTable from '../Default/DefaultTable/DefaultTable.vue';
import DefaultTitle from '../Default/DefaultTitle/DefaultTitle.vue';
import TodoListActions from '../TodoList/TodoListActions.vue';
import Rules from '@/shared/validators/formRules';

export default {
  name: "CalendarMovementForm",

  components: {
    ConfirmationModal,
    TodoListActions,
    DefaultButton,
    DefaultTitle,
    DefaultTable,
  },

  data: () => ({
    rule: null,
  }),

  mixins: [
    AuthorityManagementMixin,
    CalendarMovementMixin,
  ],

  methods: {
    handlerWindowField(item, key) {
      item[key] = !item[key];
    },

    closeForm() {
      this.resetForm();
      this.displayForm(false);
    },

    inputForm(key, value) {
      this.setFormField({[key]: value});
    },

    actions(item) {
      const actions = [
        {
          label: 'Clonar',
          icon: 'far fa-clone',
          condition: true,
          handler: () => this.cloneConfig(item),
        },
        {
          label: 'Remover',
          icon: 'far fa-trash-alt',
          condition: true,
          disabled: this.hasConfig,
          handler: () => this.removeConfig(item),
        },
      ];

      actions.sort((a, b) => a.label.localeCompare(b.label));

      return actions;
    },
  },

  computed: {
    days() {
      return Array.from({ length: 31 }, (_, i) => i + 1);
    },

    form() {
      return this.calendarForm;
    },

    tableConfig() {
      const fieldProps = {
        align: 'start',
        class: 'text-capitalize',
      };

      return  {
        calculateWidths: true,
        style: "max-height: 500px; overflow-y: auto;",
        headers: [
          // {
          //   text: 'Nível',
          //   value: 'level',
          //   slot: false,
          //   sortable: false,
          //   width: '100px',
          //   ...fieldProps,
          // },
          {
            text: 'Contratos',
            value: 'contractIds',
            width: '250px',
            sortable: false,
            slot: false,
            ...fieldProps,
          },
          {
            text: 'Tipo de Movimentação',
            value: 'movementTypes',
            sortable: false,
            slot: false,
            width: '350px',
            ...fieldProps,
          },
          {
            text: 'Janela de Movimentação',
            value: 'movementWindow',
            sortable: false,
            slot: false,
            ...fieldProps,
          },
          {
            text: 'Prazos',
            value: 'daysOfWindow',
            sortable: false,
            slot: false,
            width: '180px',
            ...fieldProps,
          },
          {
            text: 'Utiliza o dia de corte do contrato?',
            value: 'flContractCutOffDate',
            sortable: false,
            slot: false,
            ...fieldProps,
          },
          {
            text: 'Utiliza a data de início do contrato na Operadora?',
            value: 'flContractStartDate',
            sortable: false,
            slot: false,
            ...fieldProps,
          },
          {
            text: 'Seleção de datas',
            value: 'specificDays',
            sortable: false,
            slot: false,
            width: '180px',
            ...fieldProps,
          },
          {
            text: 'Ação',
            value: 'action',
            sortable: false,
            slot: false,
            width: '30px',
            ...fieldProps,
          },
        ]
      };
    },
  },

  created() {
    this.rule = new Rules();
  },
}
</script>
