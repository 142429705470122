import CalendarMovementService from "../services-http/sdi/CalendarMovementService";

export default {
  name: "CalendarMovementController",

  data: () => ({
    _service: null,
  }),

  methods: {
    async post(payload) {
      return await this.serviceClient(this._service.Post(payload));
    },

    async get(params) {
      return await this.serviceClient(this._service.Get(params));
    },

    async getConfigsByCalendarId(calendarId) {
      return await this.serviceClient(this._service.GetConfigsByCalendarId(calendarId));
    },

    async put(calendarId, payload) {
      return await this.serviceClient(this._service.Put(calendarId, payload));
    },

    async delete(calendarId) {
      return await this.serviceClient(this._service.Delete(calendarId));
    },

    async serviceClient(apiRequest) {
      try {
        return await apiRequest;
      } catch(err) {
        console.error(err);
      }
    },
  },

  created() {
    this._service = new CalendarMovementService();
  },
}