import HttpService from '../HttpService'

export default class CalendarMovementService {
  constructor() {
    this._httpService = new HttpService('/sdi/calendar-movements');
  }

  async Post(payload) {
    return await this._httpService.post('', payload);
  }

  async Get(params) {
    return await this._httpService.get('', params);
  }

  async GetConfigsByCalendarId(calendarId) {
    return await this._httpService.get(`/${calendarId}/configs`);
  }

  async Put(calendarId, payload) {
    return await this._httpService.put(`/${calendarId}`, payload);
  }

  async Delete(calendarId) {
    return await this._httpService.delete(`/${calendarId}`);
  }
}
