<template>
  <v-container fill-height fluid
    class="d-flex align-start">
    <v-row>
      <v-col cols="12">
        <DefaultTitle title="Calendário de Movimentação" />
      </v-col>

      <v-col cols="12" v-if="!showForm">
        <CalendarMovementFilter />
      </v-col>

      <v-col
        cols="12"
        class="d-flex justify-end"
        v-if="!showForm">
        <DefaultButton
          outilined
          label="Nova Configuração"
          @click="openNewForm"
          v-if="hasPermission('sdi_menu_calendario_criacao')">
          <v-icon left>
            mdi-plus
          </v-icon>

          Nova Configuração
        </DefaultButton>
      </v-col>

      <v-col cols="12"
        style="min-height: 500px;">
        <v-row no-gutters
          class="fill-height">
          <v-col cols="2">
            <CalendarMovementTable />
          </v-col>

          <v-col cols="10">
            <v-sheet
              class="d-flex align-center justify-center fill-height rounded box-shadow">
              <span
                v-if="!showForm"
                class="text-h6">
                Selecione um Calendário ou crie uma nova configuração
              </span>
              <CalendarMovementForm
                v-else
              />
            </v-sheet>
          </v-col>
        </v-row>
      </v-col>

      <v-col
        cols="12">
        <DefaultFooter />
      </v-col>

      <Loader
        :overlay="loading"
      />

      <ConfirmationModal
        ref="CalendarModal"
        title="Atenção"
        @confirm="confirmAction"
        color="primary"
      />

      <SnackbarCustomize
        ref="SnackbarCustomize"
      />
    </v-row>
  </v-container>
</template>

<script>
import AuthorityManagementMixin from '@/shared/mixins/authorityManagement/authorityManagementMixin';
import CalendarMovementFilter from '@/components/CalendarMovement/CalendarMovementFilter.vue';
import ConfirmationModal from '@/components/Modals/ConfirmationModal/ConfirmationModal.vue';
import CalendarMovementTable from '@/components/CalendarMovement/CalendarMovementTable.vue';
import CalendarMovementMixin from '@/shared/mixins/calendarMovement/calendarMovementMixin';
import CalendarMovementForm from '@/components/CalendarMovement/CalendarMovementForm.vue';
import DefaultFooter from '@/components/Default/DefaultFooter/DefaultFooter.vue';
import DefaultButton from '@/components/Default/DefaultButton/DefaultButton.vue';
import SnackbarCustomize from '@/components/CustomAlerts/SnackbarCustomize.vue';
import DefaultTitle from '@/components/Default/DefaultTitle/DefaultTitle.vue';
import Loader from '@/components/Loader/Loader.vue';
export default {
  name: "CalendarMovement",

  components: {
    CalendarMovementFilter,
    CalendarMovementTable,
    CalendarMovementForm,
    ConfirmationModal,
    SnackbarCustomize,
    DefaultFooter,
    DefaultButton,
    DefaultTitle,
    Loader,
  },

  mixins: [
    AuthorityManagementMixin,
    CalendarMovementMixin,
  ],

  async created() {
    await Promise.all([
      this.getInsuranceCarriers(),
      this.loadMovementTypes(),
      this.getContracts(),
    ])
  },

  beforeDestroy() {
    this.resetModule();
  },
}
</script>