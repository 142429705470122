<template>
  <v-btn
    v-on="$listeners"
    :class="{
      'button': true,
      'solid': !outilined,
      'outlined': outilined,
    }">
    <slot></slot>
  </v-btn>
</template>

<script>
export default {
  name: "DefaultButton",

  props: {
    outilined: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>

<style scoped>
.button {
  width: 300px;
  min-height: 50px;

  * {
    font-size: 1rem;
  }
}

.solid {
  background: #3E4959 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #1A1F2626 !important;
  border-radius: 10px !important;
  color: white;
}

.outlined {
  border: 2px solid #3e4959 !important;
  background: #F5F5F5 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #1A1F2626 !important;
  border-radius: 10px !important;
  color: #3E4959;
}
</style>