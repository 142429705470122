import CalendarMovementController from "@/controllers/calendarMovementController";
import InsuranceCarriersMixin from "@/shared/mixins/sdi/insuranceCarriersMixin";
import MovementRecordsMixin from "../movementRecords/movementRecordsMixin";
import ContractsMixin from "../sdi/contractsMixin";
import { mapGetters, mapMutations, mapState } from "vuex";

export default {
  name: "CalendarMovementMixin",

  data: () => ({
    showConfirmationModal: false,
    descriptionMessage: "",
    persistMessage: "\
      Tente mais tarde!<br><br>\
      Se persistir, entre em contato com o suporte técnico.",
    creating: false,
    action: null,
    duplicates: [],
    itemsPerPage: 25,
    sort: "id,desc",
    totalPages: 1,
    page: 1,
    model: [],
  }),

  mixins: [
    CalendarMovementController,
    InsuranceCarriersMixin,
    MovementRecordsMixin,
    ContractsMixin,
  ],

  methods: {
    ...mapMutations({
      newCalendarConfigurationForm: "calendar-movement/newCalendarConfigurationForm",
      removeFormConfiguration: "calendar-movement/removeFormConfiguration",
      cloneFormConfiguration: "calendar-movement/cloneFormConfiguration",
      setCalendarMovements: "calendar-movement/setCalendarMovements",
      displayLoading: "calendar-movement/displayLoading",
      setFormField: "calendar-movement/setFormField",
      setNewConfig: "calendar-movement/setNewConfig",
      displayForm: "calendar-movement/displayForm",
      resetModule: "calendar-movement/resetModule",
      resetForm: "calendar-movement/resetForm",

      setContracts: "contract/setContracts",
    }),

    async search(filter) {
      try {
        this.resetForm();

        const params = new URLSearchParams({
          page: this.page-1,
          size: this.itemsPerPage,
          sort: !!this.sort ? this.sort : "id,desc"
        });

        if (filter && filter.carrierId)
          params.append("carrierId", filter.carrierId);

        const result = await this.serviceResponse(this.get(params));

        if (!result || !result.data || !result.data.content) {
          this.setCalendarMovements([]);
          return;
        }

        const data = result.data.content;
        this.totalPages = result.data.totalElements;

        this.setCalendarMovements(data);

      } catch(err) {
        this.calendarMovementViewReference.SnackbarCustomize.open(
          "error",
          `Erro ao buscar as configurações de Calendário.<br>\
          ${this.persistMessage}`
        );

        console.error(err);
      }
    },

    async getConfigs() {
      try {
        this.displayLoading(true);

        let configs = [];

        let result = await this.serviceResponse(this.getConfigsByCalendarId(this.calendarForm.id), true);

          result = result.data.map((res) => ({...res, calendarId: this.calendarForm.id}));

          configs = result;

        this.displayLoading(false);

        configs.forEach((config) => {
          if (!config.daysOfWindow) {
            config.daysOfWindow = 120;
          }
        });

        this.setFormField({configs: configs});
      } catch(err) {
        this.calendarMovementViewReference.SnackbarCustomize.open(
          "error",
          `Erro ao buscar as configurações deste Calendário.<br>\
          ${this.persistMessage}`
        );

        console.error(err);
      }
    },

    async getContracts() {
      const contracts = await this.loadAllContracts();
      this.setContracts(contracts);
    },

    async saveForm() {
      if (
        !this.$refs.CalendarMovementForm.validate()
        || !this.validateIfMovementTypesAreEmpty()
        || !this.validateIfMovementWindowAreEmpty()
        || !this.validateIfHasConfigs()
        || !this.validateHasMovementTypesDuplicated()
      ) return;

      if (!this.calendarForm.id) {
        let form = {...this.calendarForm};

        await this.postCalendarForm(form);
      } else {
        let form = {...this.calendarForm};

        await this.serviceResponse(this.put(form.id, form)
        .then((res) => res.data)
        .then((res) => {
          this.calendarMovementViewReference.SnackbarCustomize.open(
            "success", "Configuração de Calendário atualizada!"
          );
        }));
      }

      this.$refs.CalendarMovementForm.resetValidation();
      this.displayForm(false);

      await this.search();
    },

    async postCalendarForm(form) {
      await this.serviceResponse(this.post(form)
      .then((res) => res.data)
      .then((res) => {
        this.calendarMovementViewReference.SnackbarCustomize.open(
          "success", "Configuração de Calendário criada!"
        );
      })
      .catch((err) => console.error(err)));
    },

    async deleteCalendarMovement() {
      try {
        await this.serviceResponse(this.delete(this.calendarForm.id)
        .then((res) => res.data)
        .then((res) => {
          this.calendarMovementViewReference.SnackbarCustomize.open(
            "success",
            "Configuração deletada!"
          );
        }));

        this.displayForm(false);

        await this.search();
      } catch(err) {
        this.calendarMovementViewReference.SnackbarCustomize.open(
          "error",
          `Erro ao tentar excluir configuração.<br>\
          ${this.persistMessage}`
        );

        console.error(err);
      }
    },

    async serviceResponse(requestFunction, notUseLoader) {
      try {
        if (!notUseLoader) this.displayLoading(true);

        return await requestFunction
          .then((response) => response)
          .catch((err) => console.error(err));
      } catch(err) {
        console.error(err);
      } finally {
        if (!notUseLoader) this.displayLoading(false);
      }
    },

    validateIfHasConfigs() {
      const hasConfig = this.calendarForm.configs && this.calendarForm.configs.length > 0;

      if (!hasConfig) {
        this.calendarMovementViewReference.SnackbarCustomize.open(
          "error",
          `Para prosseguir, adicione ao menos uma configuração.`
        );

        return false;
      }

      return true;
    },

    validateIfMovementTypesAreEmpty() {
      const movementTypesEmpty = Array.isArray(this.calendarForm.configs)
        && this.calendarForm.configs.some((config) => config.movementTypes.length === 0);

      if (movementTypesEmpty) {
        this.calendarMovementViewReference.SnackbarCustomize.open(
          "error",
          `Adicione ao menos um tipo de movimentação em suas configurações.`
        );

        return false;
      }

      return true;
    },

    validateIfMovementWindowAreEmpty() {
      const movementWindowEmpty = Array.isArray(this.calendarForm.configs)
        && this.calendarForm.configs.some((config) => !config.flFutureWindow && !config.flRetroactiveWindow);

      if (movementWindowEmpty) {
        this.calendarMovementViewReference.SnackbarCustomize.open(
          "error",
          `Verifique as suas configurações. Ao menos uma opção da Janela de Movimentaçao precisa estar selecionada.`
        );

        return false;
      }

      return true;
    },

    validateHasMovementTypesDuplicated() {
      this.duplicates = this.setDuplicates();

      if (this.duplicates.length > 0) {
        this.calendarMovementViewReference.SnackbarCustomize.open(
          "error",
          `
            A tipos de movimentações duplicadas.\
            Revise as configurações do Calendário.
          `
        );

        return false;
      }

      return true;
    },

    cleanSpecificDaysField(item) {
      item.specificDays = [];
    },

    sortSpecificDaysField(item) {
      item.specificDays.sort((a, b) => a - b);
    },

    setDuplicates() {
      if (!this.calendarForm.configs || this.calendarForm.configs.length === 0) return true;

      const seen = new Map();
      const duplicates = new Map();

      const configsToValidate = this.calendarForm.configs;

      configsToValidate.forEach((item, index) => {
        item.movementTypes.forEach((movementTypeCode) => {
          const movIndex = this.movTypes.findIndex((movType) => movType.code === movementTypeCode)

          if (seen.has(movementTypeCode)) {
            seen.get(movementTypeCode).push(movIndex);
            duplicates.set(movementTypeCode, seen.get(movementTypeCode));
          } else {
            seen.set(movementTypeCode, [movIndex]);
          }
        });
      });

      return Array.from(duplicates.entries()).map(([movementTypeCode, chipsIndex]) => ({
        movementTypeCode,
        chipsIndex,
      }));
    },

    chipDuplicates(chipIndex, movementTypeCode) {
      const duplicates = this.setDuplicates();

      const movementTypeDuplicated = duplicates.find(
        (dup) => dup.movementTypeCode === movementTypeCode)

      if (!movementTypeDuplicated) return false;

      return movementTypeDuplicated.chipsIndex.includes(chipIndex);
    },

    async confirmAction() {
      await this.action();
    },

    cloneConfig(config) {
      this.cloneFormConfiguration(config);

      this.calendarMovementViewReference.SnackbarCustomize.open(
        "success", "Configuração duplicada!"
      );
    },

    addNewConfig() {
      this.setNewConfig();

      this.calendarMovementViewReference.SnackbarCustomize.open(
        "success", "Nova Configuração adicionada!"
      );
    },

    removeConfig(config) {
      this.removeFormConfiguration(config);

      this.calendarMovementViewReference.SnackbarCustomize.open(
        "success", "Configuração removida!"
      );
    },

    requestDeleteCalendarMovement() {
      this.setModalAction(() => this.deleteCalendarMovement());
      this.setRemoveMessage();
      this.openConfirmationFormModal();
    },

    setModalAction(functionAction) {
      this.action = functionAction;
    },

    setRemoveMessage() {
      this.descriptionMessage = "Ao confirmar, você estara excluindo essa configuração.<br><br>Deseja prosseguir?";
    },

    openConfirmationFormModal() {
      this.$refs.CalendarFormModal.open();
    },

    openNewForm() {
      this.newCalendarConfigurationForm();
      this.displayForm(true);
    }
  },

  computed: {
    ...mapGetters({
      calendarMovements: "calendar-movement/calendarMovements",
      calendarForm: "calendar-movement/calendarForm",
      showForm: "calendar-movement/showForm",
      loading: "calendar-movement/loading",

      carriers: "insurance-carrier/insuranceCarriers",

      movementContracts: "contract/contracts",
      movTypes: "movement-type/movementTypes",
    }),

    calendarMovementViewReference() {
      return this.$parent.$parent.$refs;
    },

    hasConfig() {
      const configs = this.form.configs;

      return configs.length === 1
    },
  },
};
