<template>
  <DefaultTable
    ref="CalendarMovementTable"
    :items="calendarMovements"
    :tableConfig="tableConfig"
    :loading="loading"
    :totalPages="totalPages"
    :model="model"
    @itemsPerPage="itemsPerPage = $event"
    @page="page = $event"
    @sort="sort = $event"
    @search="search"
    class="fill-height calendarMovementsTable">
    <template v-slot:no-data>
      <span>
        Nenhuma configuração encontrada.
      </span>
    </template>

    <template v-slot:[`item.carrierId`]="{ item }">
      {{ item.carrierId ? getInsuranceCarrierNameById(item.carrierId) : '-' }}
    </template>
  </DefaultTable>
</template>

<script>
import CalendarMovementMixin from '@/shared/mixins/calendarMovement/calendarMovementMixin';
import DefaultTable from '../Default/DefaultTable/DefaultTable.vue';

export default {
  name: "CalendarMovementTable",

  components: {
    DefaultTable,
  },

  mixins: [
    CalendarMovementMixin,
  ],

  watch: {
    calendarForm: {
      handler(form) {
        if (Object.keys(form).length === 0) {
          this.model = [];
        }
      }
    },
  },

  methods: {
    async selectRow(row) {
      if(this.showForm) return;

      this.model = this.carrierInModel(row.carrierId)
        ? []
        : [row.carrierId];

      if (Array.isArray(this.model) && this.model.length > 0) {
        const form = { carrierId: row.carrierId, id: row.id };

        this.setFormField(form);

        await this.getConfigs();

        this.displayForm(true);
      }
    },

    rowClass(item) {
      if (!item) return '';

      return this.carrierInModel(item.carrierId)
        ? 'calendar-config-selected'
        : 'calendar-cursor-pointer';
    },

    carrierInModel(insuranceCarrierId) {
      if (!insuranceCarrierId) return false;

      return this.model.some(
        (carrierId) => carrierId === insuranceCarrierId);
    },
  },

  computed: {
    tableConfig() {
      const fieldProps = {
        align: 'center',
        class: 'text--truncate',
      };

      return  {
        on: {
          'click:row': (row) => this.selectRow(row),
        },
        'item-class': (row) => this.rowClass(row),
        singleSelect: true,
        height: '500px',
        itemKey: 'id',
        calculateWidths: true,
        headers: [
          {
            text: 'Operadora',
            value: 'carrierId',
            slot: false,
            sortable: false,
            ...fieldProps,
          },
        ]
      };
    },
  },
}
</script>

<style>
.calendar-config-selected {
  background-color: #e5e5e5;
}

.calendar-cursor-pointer {
  cursor: pointer !important;
}
</style>